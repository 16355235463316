import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h3 id="dynamic-styles">Designing bespoke Keyboards for the last 4 years</h3>
          <p>
            It all started when I was 15 (2017) when I stumbled across{" "}
            <a href="https://github.com/ruiqimao/keyboard-pcb-guide">this</a>{" "}
            keyboard pcb guide by ruiqimao, sending me down the rabbit hole of
            keyboard pcb design. However it wasn't until 2018 in which I had my first
            basic designs produced the <Link to="/ep40">EP40</Link>
            {", "}
            and the <Link to="/ep96">EP96</Link> two simple bent case style
            keyboards.
          </p>
          <p>
            Since then I've worked on a mix of over 50 
            projects such as the first custom keyboard to include a slider the{" "}
            <Link to="/eptkl">EPTKL</Link>
            {"; "}
            Pcbs for the <Link to="/raine">Raine</Link> by{" "}
            <a href="https://www.alchemistkeyboards.com/">akb</a>
            {"; "}
            Wireless keyboards with my <Link to="/ep75">EP75</Link> 
            {"; "}
            and a <Link to="/r2gcorne">Ready 2 GO Corne</Link> for{" "}
            <a href="https://mechboards.co.uk">Mechboards UK</a>
            {". "}
          </p>
          <p>
            Starting from winter 2020 I have been developing my skills of case
            design working on my upcoming wireless keyboard the{" "}
            <Link to="/ep75">EP75</Link>
            {", "} and returning to sliders with the <Link to="/ep65">EP65</Link>
            {". "}
          </p>
          <p>
            Please see our <Link to="/contact">Contact Page</Link> for ways to
            get in touch if  you need help with your current projecct or are interested
            in commisioning a new design
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
